import { useRef, useState } from 'react';
import Editor from "@monaco-editor/react";
import prettier from 'prettier';
import parserBabel from 'prettier/parser-babel';
import { FormLabel } from 'carbon-components-react';

import './JSONFormat.scss';

const JSONFormat = () => {
  const [errorText, setErrorText] = useState('');

  const originalEditorRef = useRef(null as any);
  const formattedEditorRef = useRef(null as any);

  const sampleInput = "{person: {name: 'you', lastName:'are', type:'awesome', amount:2000}}";

  const onTextChange = (value: string | undefined) => {
    let formattedCode = '';
    try {
      formattedCode = prettier.format(value || '', {
        parser: 'json',
        plugins: [parserBabel]
      });
      setErrorText('');
    } catch (error: any) {
      setErrorText(error.toString());
    }

    formattedEditorRef.current.getModel().setValue(formattedCode);
  };

  return (
    <div>
      <code style={{color: '#a00', marginBottom: '10pt'}}>
        <pre>{errorText}</pre>
      </code>
      {
        !errorText &&
        <code style={{color: '#0a0', marginBottom: '10pt'}}>
          <pre>Valid JSON!</pre>
        </code>
      }
      <div className="json-format-tool">
        <div className="text-area">
          <FormLabel>Input</FormLabel>
          <Editor
            height="80vh"
            language="json"
            defaultValue={sampleInput}
            onMount={(editor, monaco) => {
              originalEditorRef.current = editor;
            }}
            onChange={onTextChange}
          />
        </div>
        <div className="snippet-area">
        <FormLabel>Output</FormLabel>
          <Editor
            height="80vh"
            language="json"
            defaultValue={"{}"}
            onMount={(editor, monaco) => {
              editor.updateOptions({readOnly: true});
              formattedEditorRef.current = editor;
              onTextChange(sampleInput)
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default JSONFormat;
