import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink
} from 'react-router-dom';
import { Button, Search } from 'carbon-components-react';
import { Menu32 } from '@carbon/icons-react';

import './App.scss';
import JSONFormat from './tools/JSONFormat';
import Base64EncoderDecoder from './tools/Base64EncoderDecoder';
import URLEncoderDecoder from './tools/URLEncoderDecoder';
import RegExpTester from './tools/RegExpTester';
import HTMLPreview from './tools/HTMLPreview';
import MarkdownPreview from './tools/MarkdownPreview';
import BeautifyMinify from './tools/BeautifyMinify';

const tools = [
  {
    name: 'JSON Format/Validate',
    slug: 'json-format-validate',
    keywords: ['json', 'format', 'validate', 'javascript'],
    icon: null,
    component: JSONFormat
  },
  {
    name: 'Base64 Encode/Decode',
    slug: 'base64-encode-decode',
    keywords: ['base64', 'encode', 'decode', 'encoding'],
    icon: null,
    component: Base64EncoderDecoder
  },
  {
    name: 'URL Encode/Decode',
    slug: 'url-encode-decode',
    keywords: ['url', 'uri', 'encode', 'decode', 'encoding'],
    icon: null,
    component: URLEncoderDecoder
  },
  {
    name: 'RegExp Tester',
    slug: 'regexp-tester',
    keywords: ['regular', 'expression', 'regexp', 'tester'],
    icon: null,
    component: RegExpTester
  },
  {
    name: 'HTML Preview',
    slug: 'html-preview',
    keywords: ['html', 'preview', 'web'],
    icon: null,
    component: HTMLPreview
  },
  {
    name: 'Markdown Preview',
    slug: 'markdown-preview',
    keywords: ['markdown', 'preview', 'web', 'md', 'github'],
    icon: null,
    component: MarkdownPreview
  }
];

const App = () => {
  const [searchString, setSearchString] = useState('');
  const [listActive, setListActive] = useState(true);

  const isToolVisibleInList = (tool: any) => {
    if (!searchString) {
      return true;
    }

    if (tool.name.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }

    return tool.keywords.some((keyword: string) => keyword.toLowerCase().includes(searchString.toLowerCase()));
  };

  return (
    <Router>
      <div className="app">
        <div className="content">
          <div className={`tools-list ${listActive ? 'tools-list-active' : ''}`}>
            <Search
              labelText="Search tools"
              id="search-tools"
              onChange={event => setSearchString(event.target.value)}/>
            {tools.filter(tool => isToolVisibleInList(tool)).map(tool => (
              <NavLink
              key={tool.name}
              className='tools-list-item bx--btn bx--btn--tertiary'
              activeClassName='bx--btn--primary'
              to={`/${tool.slug}`}>
                {tool.name}
              </NavLink>
            ))}
          </div>
          <div className="tool-area">
            <Switch>
              {tools.map(tool => (
                <Route path={`/${tool.slug}`}>
                  <h2>{tool.name}</h2>
                  {React.createElement(tool.component)}
                </Route>
              ))}
              <Route path='/'><Redirect to={`/${tools[0].slug}`} /></Route>
            </Switch>
          </div>
        </div>
        <div className="app-header">
          <Button
            className="button-menu"
            kind="ghost"
            renderIcon={Menu32}
            hasIconOnly
            onClick={() => setListActive(!listActive)} />
          <h1>DevTools <span className='by-line'>by <a href='https://www.zvonimirfras.com/'>Z</a></span></h1>
        </div>
      </div>
    </Router>
  );
}

export default App;
