import { TextArea } from 'carbon-components-react';
import { useEffect, useRef, useState } from 'react';
import './TextAreaWithHightlight.scss';

const ua = window.navigator.userAgent.toLowerCase();
const isIE = !!ua.match(/msie|trident\/7|edge/);
const isWinPhone = ua.indexOf('windows phone') !== -1;
const isIOS = !isWinPhone && !!ua.match(/ipad|iphone|ipod/);

const applyHighlights = (text: string, regex: RegExp) => {
  text = text
    .replace(/\n$/g, '\n\n')
    .replace(regex, '<mark>$&</mark>');
  
    if (isIE) {
      // IE wraps whitespace differently in a div vs textarea, this fixes it
      text = text.replace(/ /g, ' <wbr>');
    }
    
    return text;
};

const TextAreaWithHightlight = (props: any) => {
  const [highlights, setHighlights] = useState('');
  const backdrop = useRef(null);

  useEffect(() => {
    setHighlights(applyHighlights(props.value, props.regex));
  }, [props.value, props.regex]);

  return (
    <div className='text-area-with-highlight container'>
      <div className='backdrop' ref={backdrop}>
        <div
          className={`highlights ${isIOS ? 'highlights-ios' : ''}`}
          dangerouslySetInnerHTML={{ __html: highlights}} />
      </div>
      <TextArea {...props} onScroll={(event) => {
        if (!backdrop.current) {
          return;
        }
        const scrollTop = (event.nativeEvent.target as HTMLTextAreaElement).scrollTop;
        (backdrop.current as any).scrollTop = scrollTop;
      }} />
    </div>
  );
}

export default TextAreaWithHightlight;
