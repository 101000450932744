import { useEffect, useRef, useState } from 'react';
import Editor from "@monaco-editor/react";
import { FormLabel } from 'carbon-components-react';

import './HTMLPreview.scss';

const sampleInput = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title>DevTools</title>
  </head>
  <style>
    body {
      background: white;
    }
  </style>
  <body>
    And here, ladies and gentlemen,
    is how you preview HTML. 🥳
  </body>
</html>
`;

const HTMLPreview = () => {
  const [outputUrl, setOutputUrl] = useState('' as any);
  const originalEditorRef = useRef(null as any);

  const onTextChange = (value: string | undefined) => {
    if (outputUrl) {
      URL.revokeObjectURL(outputUrl);
    }

    const blob = new Blob([value || ''], {type: 'text/html'});

    setOutputUrl(URL.createObjectURL(blob));
  };

  useEffect(() => {
    onTextChange(sampleInput);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="html-preview-tool">
        <div className="editor">
          <FormLabel>Input</FormLabel>
          <Editor
            height="80vh"
            language="html"
            defaultValue={sampleInput}
            onMount={(editor, monaco) => {
              originalEditorRef.current = editor;
            }}
            onChange={onTextChange}
          />
        </div>
        <div className="output-area">
          <FormLabel style={{width: "100%"}}>
            Output
            <a href={outputUrl} target="_blank" rel="noreferrer" style={{float: "right"}}>
              Open in new tab
            </a>
          </FormLabel>
          <iframe src={outputUrl} title="output" />
        </div>
      </div>
    </div>
  );
}

export default HTMLPreview;
