import { RadioButton, RadioButtonGroup, TextArea } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';

import './Base64EncoderDecoder.scss';

const URLEncoderDecoder = () => {
  const [mode, setMode] = useState('encode' as 'encode' | 'decode')
  const [inputValue, setInputValue] = useState('Thank you for using DevTools!');
  const [outputValue, setOutputValue] = useState('');
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    try {
      if (mode === 'encode') {
        setOutputValue(encodeURI(inputValue));
      } else {
        setOutputValue(decodeURI(inputValue));
      }
      setErrorText('');
    } catch (error: any) {
      setErrorText(error.toString());
    }
  }, [inputValue, mode]);

  return (
    <div>
      <RadioButtonGroup
      name="encode-decode-group"
      defaultSelected="encode"
      valueSelected={mode}
      onChange={newSelection => {
        setMode(newSelection as 'encode' | 'decode');
      }}>
        <RadioButton labelText="Encode" value="encode" id="encode" />
        <RadioButton labelText="Decode" value="decode" id="decode" />
      </RadioButtonGroup>
      <code style={{color: '#a00', marginBottom: '10pt'}}>
        <pre>{errorText}</pre>
      </code>
      <TextArea
        labelText="Input"
        className="text-area-input-output"
        value={inputValue}
        onChange={event => {
          setInputValue(event.target.value);
        }} />
      <TextArea
        labelText="Output"
        className="text-area-input-output"
        value={outputValue}
        readOnly />
    </div>
  );
}

export default URLEncoderDecoder;
