import { TextArea, TextInput } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import TextAreaWithHightlight from '../components/TextAreaWithHighlight';

import './RegExpTester.scss';

const RegExpTester = () => {
  const [regex, setRegex] = useState('([A-Z])\\w+');
  const [textValue, setTextValue] = useState('Thank you for using DevTools!');
  const [matchesValue, setMatchesValue] = useState([] as any[]);
  const [errorText, setErrorText] = useState('');

  const newRE = (regex: string) => new RegExp(regex, "g");

  useEffect(() => {
    try {
      const re = newRE(regex);
      let match: any;
      let newMatchesValue = [];

      while ((match = re.exec(textValue)) !== null) {
        newMatchesValue.push(match);
      }

      setMatchesValue(newMatchesValue);
      setErrorText('');
    } catch (error: any) {
      setErrorText(error.toString());
    }
  }, [regex, textValue]);

  return (
    <div>
      <code style={{color: '#a00', marginBottom: '10pt'}}>
        <pre>{errorText}</pre>
      </code>
      <TextInput
        id='regexp'
        labelText='RegExp'
        value={regex}
        onChange={event => {
          setRegex(event.target.value);
        }} />
      <TextAreaWithHightlight
        labelText="Input"
        className="text-area-input-output"
        value={textValue}
        regex={newRE(regex)}
        onChange={(event: any) => {
          setTextValue(event.target.value);
        }} />
      <TextArea
        labelText="Output"
        className="text-area-input-output"
        value={matchesValue.map(match => match[0]).join('\n')}
        readOnly />
    </div>
  );
}

export default RegExpTester;
