import { RadioButton, RadioButtonGroup, TextArea } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';

import './Base64EncoderDecoder.scss';

const Base64EncoderDecoder = () => {
  const [mode, setMode] = useState('encode' as 'encode' | 'decode')
  const [inputValue, setInputValue] = useState('Thank you for using DevTools!');
  const [outputValue, setOutputValue] = useState('');

  useEffect(() => {
    if (mode === 'encode') {
      setOutputValue(btoa(inputValue));
    } else {
      setOutputValue(atob(inputValue));
    }
  }, [inputValue, mode]);

  return (
    <div>
      <RadioButtonGroup
      name="encode-decode-group"
      defaultSelected="encode"
      valueSelected={mode}
      onChange={newSelection => {
        setMode(newSelection as 'encode' | 'decode');
      }}>
        <RadioButton labelText="Encode" value="encode" id="encode" />
        <RadioButton labelText="Decode" value="decode" id="decode" />
      </RadioButtonGroup>

      <TextArea
        labelText="Input"
        className="text-area-input-output"
        value={inputValue}
        onChange={event => {
          setInputValue(event.target.value);
        }} />
      <TextArea
        labelText="Output"
        className="text-area-input-output"
        value={outputValue}
        readOnly />
    </div>
  );
}

export default Base64EncoderDecoder;
