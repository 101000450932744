import { useEffect, useRef, useState } from 'react';
import marked from 'marked';
import Editor from "@monaco-editor/react";
import { Button, FormLabel } from 'carbon-components-react';

import './MarkdownPreview.scss';

const sampleInput = `# Let's preview markdown

## With a subtitle

- and some
- bullets
- etc :)
`;

const markdownReferenceText = `Markdown Quick Reference
========================

Overview and examples of Markdown syntax.

Text Formatting
----------------------

Use *stars* or _underscores_ for italics.
**Double stars** and __double underscores__ for bold.
***Three together*** for ___both___.

Leave a blank line between chunks of text for paragraphs.

> This is how you do a block quote. This chunk's multiple lines will all be
> indented a bit from the rest of the text.
>
> > Multiple levels of block quotes also work.

For inline code surround text with backticks like \`myAwesomeFunction()\`

For multiline code you have multiple options:

\`\`\`
surround code with
triple backticks
\`\`\`

or

    indent the line with a tab
    or at least four spaces
          Extra spaces work here too.

>     You can also use preformatted text with your blockquotes
>     as long as you add at least five spaces.


Headings
--------

Using three or more equals signs on a line under a heading makes it into an "h1".
Three or more hyphens under a line makes it into an "h2".

You can also use multiple pound symbols (\`#\`) before a heading

**Examples:**

This is H1
==========

This is H2
----------

# This is H1
## This is H2
### This is H3
#### This is H4
##### This is H6
###### H6 is the max

Links
-----

Bare URL: <https://dev-tools.zvonimirfras.com/>.

Inline link: [DevTools](https://dev-tools.zvonimirfras.com/).

Reference-style link to Wikipedia [1].

Pretty link to [Ecosia]. The reference-style and pretty links both automatically use the
links defined below, but they could be defined *anywhere* in the markdown and are removed from the HTML.
The names are also case insensitive, so you can use [EcOSia] and it links correctly.

[1]: https://www.wikipedia.org
[Ecosia]: https://www.ecosia.org/

You can add title attributes to links by adding text after a link.

This is the [inline link](https://duckduckgo.com/ "DuckDuckGo") with a "DuckDuckGo" title.

There are 2 more ways to add titles with references: [photography lovers click here] and [DevTools].

[photography lovers click here]: https://photography.zvonimirfras.com/ (Feast your eyes 👀)
[DevTools]: https://dev-tools.zvonimirfras.com/ "Your favorite dev tools!"

Email addresses: <test@example.com>.

Lists
-----

* Use asterisks
* for bulleted (unordered) lists
- You can also use hyphens
+ Or plus symbols

1. Numbered lists
2. start with a number
0869. any number
1. doesn't matter (this is numbered 4 when rendered)

Advanced lists:

- This top-level list is wrapped in paragraph tags
- This generates an extra space between each top-level item.

- You do it by adding a blank line between the items

- This nested list also has blank lines between the list items.

- Nested list:
  1. Start your regular list
  2. Indent nested lists with two spaces
  3. Further nesting by indenting with two more spaces
    * This line is indented with four spaces.

- You can keep typing and either continue the bullet
on the next line with no indentation.

- Or you can also indent the next
  line a bit for a prettier look.

- You can put large blocks of text in your list by just indenting with two spaces.

  This is formatted the same as code, but it's in a different context so it's fine.

  Keep adding paragraphs to a single list item by adding a blank line and then keep
  indenting the paragraphs with two spaces.

- Blockquotes in lists

  > Just like this. You can also
  > nest lists inside blockquotes!
  > - Another
  > - list

- Preformatted text in lists

      Just indent an additional four spaces.
  
  or

  \`\`\`
  triple backticks again
  \`\`\`

Horizontal Rule
---------------

Put at least three hyphens, asterisks, or underscores on a line by themselves. You can even put spaces between the characters.

---
**********************
_ _ _ _ _

Keep in mind that three hyphens under any text turns that text into a heading, so add a blank like if you use hyphens.

Images
------

Images work exactly like links, but they have exclamation points in front.

![Google Logo](https://www.google.com/images/errors/logo_sm.gif)

Inline HTML
-----------

Span-level HTML <u>can *still* use markdown</u>.

Block level elements must be separated from text by a blank line and must not have any spaces
before the opening and closing HTML.

<div style='font-family: "Comic Sans MS", "Comic Sans", cursive;'>
Markdown does **not** work in here for most markdown parsers.
</div>
`;

const MarkdownPreview = () => {
  const [outputUrl, setOutputUrl] = useState('' as any);
  const originalEditorRef = useRef(null as any);

  const onTextChange = (value: string | undefined) => {
    if (outputUrl) {
      URL.revokeObjectURL(outputUrl);
    }

    const blob = new Blob([marked(value || '')], {type: 'text/html'});

    setOutputUrl(URL.createObjectURL(blob));
  };

  useEffect(() => {
    onTextChange(sampleInput);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="markdown-preview-tool">
        <div className="editor">
          <FormLabel style={{width: "100%"}}>
            Input
            <Button
            kind="ghost"
            size="sm"
            onClick={() => {
              originalEditorRef.current.getModel().setValue(markdownReferenceText);
            }}
            style={{float: "right", minHeight: "auto", height: "16px", fontSize: "12px"}}>
              Load markdown reference
            </Button>
          </FormLabel>
          <Editor
            height="80vh"
            language="markdown"
            defaultValue={sampleInput}
            onMount={(editor, monaco) => {
              originalEditorRef.current = editor;
            }}
            onChange={onTextChange}
          />
        </div>
        <div className="output-area">
          <FormLabel style={{width: "100%"}}>
            Output
            <a href={outputUrl} target="_blank" rel="noreferrer" style={{float: "right"}}>
              Open in new tab
            </a>
          </FormLabel>
          <iframe src={outputUrl} title="output" />
        </div>
      </div>
    </div>
  );
}

export default MarkdownPreview;
